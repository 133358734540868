import React from 'react'

export default function Contact() {
    //let data = React.useState({name: "", email: "", message: ""})

    return (
        <div>
            Contact Page
        </div>
    )
}
